
import Slider from '../../components/Slider/Slider'

const Public = () => {     

    const content = (        
        <div className='wrapper'>
                <Slider/>                 
        </div>
    )
    return content
}
export default Public